html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f1f1f1;
}

table button.del {
  background: #cc1515;
}

table button.sav {
  background: #87b32d;
}
table button {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: none;
  background: cadetblue;
  color: white;
  text-transform: capitalize;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 2px 2px 1px #00000021;
  transition: 0.2s all;
  margin: 3px 0;
}

table button:hover {
  transform: translateY(1px);
  box-shadow: 0 2px 2px 0px #00000021;
  /* padding: 15px; */
}

table input {
  border-radius: 3px;
  border: none;
  box-shadow: 0 2px 2px 1px #00000021;
  padding: 20px;
}

table tbody tr:last-child {
  border-top: 5px solid #81d281;
}
